import React from "react"
import { graphql } from "gatsby"
import Notice from "../../components/notice"
import Navbar from "../../components/navbar"
import CallToAction from "../../components/callToAction"
import Footer from "../../components/footer"
import ResourceFeatured from "../../components/resourceFeatured"
import ResourceHeader from "../../components/resourceHeader"
import ResourceMostRecent from "../../components/resourceMostRecent"
import Seo from '../../components/seo'

const ResourceTag = ({ data, pageContext }) => {
  const tagName = pageContext.slug
  const featured = data.featured.edges[0]?.node

  const title = featured?.title
  const metaDescription = featured?.synopsis?.eventDatetime
  const imageUrl = featured?.resourceGraphic?.file?.url
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const resourceData = data.allContentfulResource.edges
  const tagsPosts = data.tags.edges
  const tags = []
  tagsPosts.forEach(({ node: post }) => {
    !tags.includes(post.resourceTag) && tags.push(post.resourceTag)
  })
  const NoticeData = data?.notice?.edges?.[0]?.node
  const NavbarData = data?.navbar?.edges?.[1]?.node
  const CalltoActionData = data?.callToAction?.edges?.[0]?.node
  const footerData = data?.footerColumn?.edges?.[1]?.node

  const relatedPosts = []
  resourceData.forEach(({ node: res }) => {
    !relatedPosts.includes(res) && relatedPosts.push(res)
  })
  return (
    <>
      <Seo 
        title={title} 
        metaDescription={metaDescription} 
        imageUrl={imageUrl} 
        url={url} 
      />
      {NoticeData && <Notice NoticeData={NoticeData} />}
      {NavbarData && <Navbar NavbarData={NavbarData} />}
      {featured && <ResourceFeatured featuredPosts={featured} backlink={true} />}
      <ResourceHeader tags={tags} tagName={tagName} />
      <ResourceMostRecent
        data={relatedPosts}
        renderPagination={true}
        limit={6}
        tagName={tagName}
      />
      {CalltoActionData && <CallToAction CalltoActionData={CalltoActionData} />}
      <Footer variant="dark" footerData={footerData?.footerColumns} atlasOneLogo={footerData?.atlasOneLogo} />
    </>
  )
}
export default ResourceTag

export const query = graphql`
  query resourcesTagQuery ( $slug: String!){
  allContentfulResource(sort : { fields: [updatedAt], order:DESC }, filter: {  resourceTag : { eq: $slug } }){
    edges {
      node {
        title
        eventDatetime
        synopsis
        description {
          raw
        }
        featured
        resourceGraphic {
            title
            file {
                url
            }
        }
        resourceTag
        hubspotFormId
      }
    }
  }
  featured:  allContentfulResource(limit: 1, sort : { fields: [updatedAt], order:DESC },filter: {  featured : { eq: true } }) {
    edges {
      node {
        title
        eventDatetime
        synopsis
        description {
          raw
        }
        featured
        resourceGraphic {
            title
            file {
                url
            }
        }
        resourceTag
        hubspotFormId
      }
    }
  }
  tags: allContentfulResource{
          edges{
              node{
                  resourceTag
              }
          }
  }
  notice: allContentfulNotice {
    edges {
      node {
        elementType
        theme
        noticeTitle {
          raw
        }
        tagline
        actionUrl
        noticeActionText
      }
    }
  }

  navbar: allContentfulHeader {
    edges {
      node {
        atlasOneLogo {
          image {
            file {
              url
            }
            title
          }
        }
        headerButtons {
          ... on ContentfulPrimaryButton {
            elementType
            id
            theme
            title
            url
          }
          ... on ContentfulSecondaryButton {
            elementType
            id
            theme
            title
            url
          }
        }
        headerMenuItems {
          id
          title
          headerMenuDropdown {
            items {
              description
              icon {
                file {
                  url
                }
                title
              }
              title
              url
              page {
                slug
                title
              }
            }
          }
        }
      }
    }
  }

  callToAction: allContentfulCallToAction {
    edges {
      node {
        elementType
        theme
        title
        desc:description
        buttons {
          ... on ContentfulSecondaryButton {
            elementType
            theme
            title
            url
          }
          ... on ContentfulPrimaryButton {
            elementType
            theme
            title
            url
          }
        }
      }
    }
  }

  footerColumn: allContentfulFooter {
    edges {
      node {
        elementType
        footerColumns {
          columnTitle
          links {
            title
            description
            icon {
              file {
                url
              }
            }
            url
            page {
              slug
              title
            }
          }
        }
        atlasOneLogo {
          image {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
  allContentfulPage {
    edges {
      node {
        slug
        title
        metaTitle
        metaDescription
        ogImage{
          file{
              url
          }
        }
        sections {
          ... on ContentfulNotice {
            elementType
            theme
            noticeTitle {
              raw
            }
            tagline
            actionUrl
            noticeActionText
          }
          ... on ContentfulHeader {
            headerMenuItems {
              title
              headerMenuDropdown {
                items {
                  description
                  icon {
                    file {
                      url
                    }
                    title
                  }
                  title
                  url
                  page {
                    slug
                    title
                  }
                }
              }
            }
            headerButtons {
              ... on ContentfulPrimaryButton {
                elementType
                id
                theme
                title
                url 
              }
              ... on ContentfulSecondaryButton {
                elementType
                id
                theme
                title
                url 
              }
            }
            atlasOneLogo {
              image {
                file {
                  url
                }
                title
              }
            }
          }
          ... on ContentfulCallToAction {
            elementType
            theme
            title
            buttons {
              ... on ContentfulSecondaryButton {
                elementType
                theme
                title
                url
              }
              ... on ContentfulPrimaryButton {
                elementType
                theme
                title
                url
              }
            }
          }
          ... on ContentfulFooter {
            elementType
            ... on Node {
              ... on ContentfulFooterColumn {
                columnTitle
                links {
                    title
                    description
                    icon {
                        file {
                            url
                        }
                    }
                    url
                    page {
                      slug
                      title
                    }
                }
              }
            }
            atlasOneLogo {
              image {
                file {
                  url
                }
                title
              }
            }
          }
        }
      }
    }
  }
}`

